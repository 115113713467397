import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import Hero from 'components/hero';
import Cta from 'components/cta';
import Benefits from 'components/benefits';
import Reasons from 'components/reasons';
import Table from 'components/table';
import TableSwipe from 'components/tableSwipe';
import FeaturesSingle from 'components/features-list/featuresSingle';
import Platforms from 'components/platforms';
import Faqs from 'components/faqs';

const Website = ({ data }) => (
  <Layout>
    <Head pageTitle={data.websiteJson.hero.title} />
    <Hero hero={data.websiteJson.hero} />
    <Platforms items={data.websiteJson.platforms} />

    <Cta cta={data.websiteJson.cta} />
    <FeaturesSingle feature={data.websiteJson.feature} isCard={true} />

    <Benefits benefit={data.websiteJson.benefit} columns={3} />
    <FeaturesSingle feature={data.websiteJson.process} />
    <Reasons reason={data.websiteJson.reason} size="medium" headType="medium" />
    <Table data={data.websiteJson.pricing} />
    <TableSwipe data={data.websiteJson.pricing} />
    <Faqs faqs={data.websiteJson.faqs} />

    <Cta cta={data.websiteJson.cta2} />
  </Layout>
);

Website.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Website;

export const query = graphql`
  query WebsiteQuery {
    websiteJson {
      hero {
        title
        content {
          childMarkdownRemark {
            html
          }
        }
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      platforms {
        name
        image {
          childImageSharp {
            fluid(maxHeight: 144, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      feature {
        title
        content
        features {
          position
          headline
          text
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 546) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      benefit {
        title
        benefits {
          icon
          headline
          text
        }
      }
      process {
        title
        content
        features {
          position
          icon
          headline
          text
          image {
            childImageSharp {
              fluid(quality: 100, maxWidth: 546) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      reason {
        title
        content
        reasons {
          icon
          text
        }
      }
      pricing {
        title
        content
        packages {
          type
          pages
          custom
          forms
          ePayments
          eCommerce
          restrictions
          memberships
          timeline
          hosting
          price
        }
      }
      faqs {
        title
        faqs {
          title
          content
        }
      }
      cta {
        title
        content
        image {
          childImageSharp {
            fluid(quality: 100, maxWidth: 546) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        action
      }
      cta2 {
        title
        content
        action
      }
    }
  }
`;
