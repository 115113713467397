import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  FiActivity,
  FiSearch,
  FiEdit3,
  FiCheckCircle,
  FiFeather
} from 'react-icons/fi';

import Title from 'components/title';
import Text from 'components/utilities/text';

import { Header, Container, Section } from './featuresSingle.css';

const iconSwitch = icon => {
  switch (icon) {
    case 'FiActivity':
      return <FiActivity size="24px" color="573bee" className="mr-4" />;
    case 'FiEdit3':
      return <FiEdit3 size="24px" color="573bee" className="mr-4" />;
    case 'FiSearch':
      return <FiSearch size="24px" color="573bee" className="mr-4" />;
    case 'FiCheckCircle':
      return <FiCheckCircle size="24px" color="573bee" className="mr-4" />;
    case 'FiFeather':
      return <FiFeather size="24px" color="573bee" className="mr-4" />;
    default:
      return null;
  }
};

const FeaturesSingle = ({ feature, isCard = false }) => {
  const { title, content, features } = feature;

  return (
    <>
      <Header>
        <Title as="h2" className="text-center">{title}</Title>
        <Text as="p" className="text-center">{content}</Text>
      </Header>
      <Container>
        {(features || []).map(f => {
          const { icon, headline, text, image, position = 'left' } = f;

          if (position === 'right') {
            return (
              <Section key={headline} isCard={isCard}>
                <div className="content">
                  <div className="d-flex align-items-center mb-4">
                    {iconSwitch(icon)}
                    <h3>{headline}</h3>
                  </div>
                  <p>{text}</p>
                </div>
                <Img fluid={image ? image.childImageSharp.fluid : {}} alt={headline} />
              </Section>
            );
          } else {
            return (
              <Section key={headline} isCard={isCard}>
                <Img fluid={image ? image.childImageSharp.fluid : {}} alt={headline} />
                <div className="content">
                  <div className="d-flex align-items-center mb-4">
                    {iconSwitch(icon)}
                    <h3>{headline}</h3>
                  </div>
                  <p>{text}</p>
                </div>
              </Section>
            );
            
          }

        })}
      </Container>
    </>
  );
};

FeaturesSingle.propTypes = {
  feature: PropTypes.object.isRequired,
  isCard: PropTypes.boolean,
};

export default FeaturesSingle;