import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { FiCheckCircle, FiMinus } from 'react-icons/fi';

import Title from 'components/title';
import Text from 'components/utilities/text';

import { Container, Column, Header } from './tableSwipe.css';

const renderTable = items => {
  return items.map(item => {
    return (
      <Column key={item.type}>
        <div className="bg-info">
          <h3 className="font-weight-bold text-light">{item.type}</h3>
        </div>
        <div>
          <FiCheckCircle color="#57BD7B" size="24px" />
        </div>
        <div>
          <FiCheckCircle color="#57BD7B" size="24px" />
        </div>
        <div>
          <FiCheckCircle color="#57BD7B" size="24px" />
        </div>
        <div>
          <FiCheckCircle color="#57BD7B" size="24px" />
        </div>
        <div>
          <FiCheckCircle color="#57BD7B" size="24px" />
        </div>
        <div>
          <p>{item.pages}</p>
        </div>
        <div>
          {item.custom ? (
            <FiCheckCircle color="#57BD7B" size="24px" />
          ) : (
            <FiMinus size="24px" />
          )}
        </div>
        <div>
          {item.forms ? (
            <FiCheckCircle color="#57BD7B" size="24px" />
          ) : (
            <FiMinus size="24px" />
          )}
        </div>
        <div>
          {item.ePayments ? (
            <FiCheckCircle color="#57BD7B" size="24px" />
          ) : (
            <FiMinus size="24px" />
          )}
        </div>
        <div>
          {item.eCommerce ? (
            <FiCheckCircle color="#57BD7B" size="24px" />
          ) : (
            <FiMinus size="24px" />
          )}
        </div>
        <div>
          {item.restrictions ? (
            <FiCheckCircle color="#57BD7B" size="24px" />
          ) : (
            <FiMinus size="24px" />
          )}
        </div>
        <div>
          {item.memberships ? (
            <FiCheckCircle color="#57BD7B" size="24px" />
          ) : (
            <FiMinus size="24px" />
          )}
        </div>
        <div>
          <p>{item.timeline}</p>
        </div>
        <div>
          <p>{item.hosting}</p>
        </div>
        <div>
          <p className="font-weight-bold">{item.price}</p>
        </div>
      </Column>
    )
  });
};

const DemoSimple = ({ data }) => {
  const { title, content, packages } = data;

  return (
    <>
      <Header>
        <Title as="h2" className="text-center">
          {title && title}
        </Title>
        <Text as="p" className="text-center">{content && content}</Text>
      </Header>
      <Container>
        <Column>
          <div className="bg-info">
            <h3 className="font-weight-bold text-light">&nbsp;</h3>
          </div>
          <div className="text-center">
            <p>Built on Wordpress</p>
          </div>
          <div className="text-center">
            <p>Mobile Responsive</p>
          </div>
          <div className="text-center">
            <p>Blog Functionality</p>
          </div>
          <div className="text-center">
            <p>SEO Optimized</p>
          </div>
          <div className="text-center">
            <p>Speed Optimized</p>
          </div>
          <div className="text-center">
            <p>Number of Pages</p>
          </div>
          <div className="text-center">
            <p>Custom Design</p>
          </div>
          <div className="text-center">
            <p>Custom Forms</p>
          </div>
          <div className="text-center">
            <p>Stripe + PayPal</p>
          </div>
          <div className="text-center">
            <p>Products + Services</p>
          </div>
          <div className="text-center">
            <p>Restrict Content</p>
          </div>
          <div className="text-center">
            <p>Memberships</p>
          </div>
          <div className="text-center">
            <p>Average Timelines</p>
          </div>
          <div className="text-center">
            <p>Required Hosting</p>
          </div>
          <div className="text-center">
            <p>Starting Price</p>
          </div>
        </Column>

        <SwipeableViews enableMouseEvents>{renderTable(packages)}</SwipeableViews>
      </Container>
    </>
  );
};

export default DemoSimple;
