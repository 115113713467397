import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 24px;
  margin: 50px auto 100px auto;
  max-width: 1140px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
  }
  h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
  p {
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
    color: #000000;
  }
`;

export const Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
  align-items: center;
  margin: 25px auto;
  max-width: 1140px;
  padding: ${ props => props.isCard && "48px"};
  background: ${ props => props.isCard && "#ffffff"};
  border-radius: ${ props => props.isCard && "3px"};
  box-shadow: ${ props => props.isCard && "0 6px 8px 0 rgba(164, 164, 164, 0.21)"};
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-row-gap: 24px;
    padding: ${ props => props.isCard ? "24px" : "0px" };
    margin: 0 24px;
    .content {
      order: 2;
      text-align: center;
      div {
        justify-content: center;
      }
    }
  }
  h3 {
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #000000;
  }
  p {
    margin-bottom: 0;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 1px;
    color: #000000;
  }
`;

export const Header = styled.div`
  margin: 50px auto;
  max-width: 1140px;
  @media (max-width: 768px) {
    margin-left: 24px;
    margin-right: 24px;
  }
`