import styled from 'styled-components';

export const Column = styled.div`
  border-right: 1px solid #E5E7EB;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    border-bottom: 1px solid #E5E7EB;

    h3 {
      margin: 0;
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      color: #000000;
    }
    p {
      margin-bottom: 0;
      font-family: 'Nunito', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      letter-spacing: 1px;
      color: #0a171c;
    }
  }
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-content: center;
  margin: 50px auto 100px auto;
  max-width: 1140px;
  background: #ffffff;
  border: 1px solid #E5E7EB;
  border-right: none;
  border-radius: 5px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Header = styled.div`
  margin: 50px auto;
  max-width: 1140px;
  @media (max-width: 768px) {
    display: none;
    margin-left: 24px;
    margin-right: 24px;
  }
`;
